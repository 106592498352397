<template>
	<div class="animated fadeIn">
		<b-card title="Asset" sub-title="Manages all of the assets accountable for each company">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<AccountableAssets :isSuperAdmin="isSuperAdmin" :isViewer="isViewer"
					:filterByOptions="filterByOptions" />
			</b-container>
		</b-card>

		<!-- Modals here -->

		<!-- Single Asset Operations -->
		<ActivateAsset :isSuperAdmin="isSuperAdmin" :allCompanyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />
		<UpdateAssetDetails :isSuperAdmin="isSuperAdmin" :allCompanyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />
		<MarkAsLostAsset />
		<ChangeAssetLocation :companyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />

		<!-- Multi Assets Operations -->
		<ActivateAssets :isSuperAdmin="isSuperAdmin" :allCompanyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />
		<MarkAsLostAssets />
		<ChangeAssetsLocation :companyOptions="filterByOptions.companyItems"
			:allStorageLocationOptions="filterByOptions.storageLocationItems" />

		<AssignConditionOnAssets />
	</div>
</template>

<script>
// Components
import AccountableAssets from '@/views/asset/asset/AccountableAssets';
import ActivateAsset from '@/views/asset/asset/ActivateAsset';
import ActivateAssets from '@/views/asset/asset/ActivateAssets';
import UpdateAssetDetails from '@/views/asset/asset/UpdateAssetDetails';
import MarkAsLostAsset from '@/views/asset/asset/MarkAsLostAsset';
import MarkAsLostAssets from '@/views/asset/asset/MarkAsLostAssets';
import ChangeAssetLocation from '@/views/asset/asset/ChangeAssetLocation';
import ChangeAssetsLocation from '@/views/asset/asset/ChangeAssetsLocation';
import AssignConditionOnAssets from '@/views/asset/asset/AssignConditionOnAssets';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'assets',
	components: {
		AccountableAssets,
		ActivateAsset,
		ActivateAssets,
		UpdateAssetDetails,
		MarkAsLostAsset,
		MarkAsLostAssets,
		ChangeAssetLocation,
		ChangeAssetsLocation,
		AssignConditionOnAssets,
		Loading,
	},
	data() {
		return {
			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			filterByOptions: {
				companyItems: [],
				connectionItems: [],
				storageLocationItems: [],
				assetTypeItems: [],
			},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	mounted() {
		setTimeout(() => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// Show Loader
				this.isLoading = true;

				this.allCompaniesObj = this.$store.getters.companies;
				this.allStorageLocationsObj = this.$store.getters.storageLocations;
				this.allAssetTypesObj = this.$store.getters.assetTypes;

				let companyItems = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);
				this.filterByOptions.companyItems = companyItems;

				let storageLocationItems =
					DropDownItemsUtil.retrieveStorageLocationItems(
						this.allStorageLocationsObj
					);
				this.filterByOptions.storageLocationItems = storageLocationItems;

				let assetTypeItems = DropDownItemsUtil.retrieveAssetTypeItems(
					this.allAssetTypesObj
				);
				this.filterByOptions.assetTypeItems = assetTypeItems;
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {},
};
</script>

<style scoped>

</style>
