<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
			@click.stop="row.toggleDetails" class="mr-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>
	</div>
</template>

<script>
export default {
	name: 'asset-row-actions',
	props: {
		row: {
			type: Object,
			required: true
		}
	},
	methods: {}
};
</script>

<style></style>
