<template>
	<b-modal
		id="assign-condition-on-assets"
		title="Assign Condition On Assets"
		size="lg"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12">
						<i class="icon-direction"></i> Assign condition,
						<b-badge variant="success">Good</b-badge> or
						<b-badge variant="danger">Damaged</b-badge>, on each asset.
					</b-col>
				</b-row>
				<b-row class="mb-0">
					<b-col sm="12" class="my-2">
						<b-table
							show-empty
							striped
							hover
							fixed
							:items="selAssets"
							:fields="fields"
							:current-page="currentPage"
							:per-page="perPage"
							:filter="filter"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:sort-direction="sortDirection"
							responsive
						>
							<template v-slot:cell(condition)="row">
								<b-form-select
									:name="row.item.assetCode + ' Condition'"
									v-model="selConditions[row.item.assetCode]"
									v-validate="'required'"
									:options="assetConditionOptions"
									class="mr-2"
								/>
								<span
									v-show="errors.has(row.item.assetCode + ' Condition')"
									class="help-block"
									>{{ errors.first(row.item.assetCode + ' Condition') }}</span
								>
							</template>
						</b-table>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col md="4" class="my-1">
						<b-pagination
							:total-rows="totalRows"
							:per-page="perPage"
							v-model="currentPage"
							class="my-0"
						/>
					</b-col>
					<b-col md="2" sm="12" offset-md="2" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="6" class="my-1">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'assign-condition-on-assets',
	components: {
		Loading,
	},
	data() {
		return {
			selConditions: {},
			selAssets: {},
			currUserId: this.$store.getters.loggedUser.id,
			assetConditionOptions: config.assetConditionStatus,

			// table fields
			fields: [
				{
					key: 'assetCode',
					sortable: true,
				},
				'assetType',
				'condition',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelAssetsToAssignCondition', (selAssets) => {
			this.selAssets = selAssets;
			this.processSelAssets(selAssets);
		});
	},
	methods: {
		async processSelAssets(selAssets) {
			// Show Loader
			this.Loading = true;

			let assetCodes = _.map(selAssets, 'assetCode');
			let assetsObjResult = await this.$store.dispatch('getAssetsByAssetCode', {
				assetCodes: assetCodes,
			});
			let assetsObj = assetsObjResult[0];

			let assetsArr = Object.values(assetsObj);
			this.totalRows = assetsArr.length;
			this.initAssetConditions(assetsArr);

			// Hide Loader
			this.Loading = false;
		},
		initAssetConditions(assetsArr) {
			assetsArr.forEach((asset) => {
				let assetCode = asset.assetCode;
				let assetDetails = asset.details;
				let condition =
					assetDetails.condition && assetDetails.condition.length > 0
						? assetDetails.condition
						: null;
				this.selConditions[assetCode] = condition;
			});
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			this.handleSubmit();
		},
		getParam() {
			let assets = {};

			let assetCodes = _.map(this.selAssets, 'assetCode');
			for (const assetCode of assetCodes) {
				let index = _.findIndex(this.selAssets, function (o) {
					return o.assetCode === assetCode;
				});
				assets[assetCode] = this.selAssets[index];
				if (_.isEmpty(assets[assetCode].details)) {
					assets[assetCode].details = {};
				}
				assets[assetCode].details.condition = this.selConditions[assetCode];
			}

			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assets: assets,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetApi.updateAssets(this.getParam());

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					this.$refs.modal.hide();
					EventBus.$emit('onCloseAssignConditionOnAssets');
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.warning(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					'Error assigning condition on asset/s. Please try again.'
				);
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAssetsToAssignCondition');
	},
};
</script>

<style scoped>
</style>