<template>
	<div>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" />
		<b-card no-body>
			<b-tabs content-class="mt-3">
				<b-tab title="Details" active>
					<b-card-text>
						<b-row class="mb-2">
							<b-col sm="3" v-if="
								selAsset.status !== 'Inactive' &&
								selAsset.status !== 'Lost' &&
								selAsset.details &&
								selAsset.details.imgUrl
							">
								<b-img v-img-orientation-changer :src="selAsset.details.imgUrl" alt="Responsive image"
									thumbnail fluid />
							</b-col>

							<b-col sm="9">
								<b-form-group label="Asset Code:" label-cols-sm="2" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									{{ selAsset.assetCode }}
								</b-form-group>

								<b-form-group label="Asset Name:" label-cols-sm="2" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									{{
										selAsset.details && selAsset.details.name
										? selAsset.details.name
										: '-'
									}}
								</b-form-group>

								<b-form-group label="Serial No:" label-cols-sm="2" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									{{
										selAsset.details && selAsset.details.serialNo
										? selAsset.details.serialNo
										: '-'
									}}
								</b-form-group>

								<b-form-group label="Description:" label-cols-sm="2" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									{{
										selAsset.details && selAsset.details.description
										? selAsset.details.description
										: '-'
									}}
								</b-form-group>

								<b-form-group label="Condition:" label-cols-sm="2" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<b-badge v-if="selAsset.details && selAsset.details.condition === 'Good'"
										variant="success">
										Good
									</b-badge>
									<b-badge v-else variant="danger">Damaged</b-badge>
								</b-form-group>

								<b-form-group label="Status:" label-cols-sm="2" label-class="font-weight-bold pt-0"
									label-align-sm="right">
									<AssetRowStatus :selAsset="selAsset" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-card-text>
				</b-tab>

				<b-tab title="Activation" v-if="selAsset.status !== 'Inactive' && selAsset.status !== 'Lost'">
					<b-card-text>
						<b-form-group label="Date Activated:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ showFormattedDateWithTime(selAsset.dateActivated) }}
						</b-form-group>

						<b-form-group label="Activated By:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ selAsset.activatedBy }}
						</b-form-group>

						<b-form-group label="Asset Age:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getNoOfDays(selAsset.dateActivated) }} day(s)
						</b-form-group>

						<b-form-group v-show="selAsset.status === 'Stored'" label="Last Inventory Details:"
							label-cols-sm="3" label-class="font-weight-bold pt-0" label-align-sm="right">
							{{
								details.inventorySessionId ? details.inventorySessionId : '-'
							}}
							at
							{{
								details.lastInventoryDate
								? showFormattedDateWithTime(details.lastInventoryDate)
								: '-'
							}}
							by {{ inventoriedBy }}
						</b-form-group>
					</b-card-text>
				</b-tab>

				<b-tab title="Current Location" v-if="selAsset.status !== 'Inactive' && selAsset.status !== 'Lost'">
					<b-card-text>
						<b-form-group label="Company:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ currentLocation.company }}
						</b-form-group>

						<b-form-group label="Storage Location:" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ currentLocation.storageLocation }}
						</b-form-group>

						<b-form-group v-show="selAsset.status != 'Lost'" label="Date Stored:" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ showFormattedDateWithTime(currentLocation.dateStored) }}
						</b-form-group>

						<b-form-group v-show="selAsset.status != 'Lost'" label="Asset Tenure:" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ getNoOfDays(currentLocation.dateStored) }} day(s)
						</b-form-group>

						<b-form-group v-show="selAsset.status === 'Lost'" label="Date Lost:" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ showFormattedDateWithTime(currentLocation.dateLost) }}
						</b-form-group>

						<b-form-group v-show="selAsset.status === 'Lost'" label="No of Days Lost:" label-cols-sm="3"
							label-class="font-weight-bold pt-0" label-align-sm="right">
							{{ getNoOfDays(currentLocation.dateLost) }} day(s)
						</b-form-group>
					</b-card-text>
				</b-tab>
			</b-tabs>
		</b-card>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button v-show="
					selAsset &&
					(selAsset.status === 'Inactive' || selAsset.status === 'Lost') &&
					!isViewer
				" size="sm" v-b-modal.activate-asset v-b-tooltip.hover.top="'Activate Asset'" variant="warning"
					@click.stop="updateSelAsset(selAsset, row.index, $event.target)" class="mr-1">
					<i class="fa fa-bolt"></i>
				</b-button>

				<b-button v-show="selAsset && selAsset.status === 'Stored' && !isViewer" size="sm"
					v-b-modal.update-asset-details v-b-tooltip.hover.top="'Update Asset Details'" variant="dark"
					@click.stop="updateSelAsset(selAsset, row.index, $event.target)" class="mr-1">
					<i class="fa fa-pencil"></i>
				</b-button>

				<b-button v-show="selAsset && selAsset.status === 'Stored' && !isViewer" size="sm"
					v-b-modal.mark-as-lost-asset v-b-tooltip.hover.top="'Mark as Lost'" variant="danger"
					@click.stop="updateSelAsset(selAsset, row.index, $event.target)" class="mr-1">
					<i class="fa fa-ban"></i>
				</b-button>

				<b-button v-show="selAsset && selAsset.status === 'Stored' && !isViewer" size="sm"
					v-b-modal.change-asset-location v-b-tooltip.hover.top="'Change Location'" variant="primary"
					@click.stop="updateSelAsset(selAsset, row.index, $event.target)" class="mr-1">
					<i class="fa fa-truck"></i>
				</b-button>

				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Component
import AssetRowStatus from '@/views/asset/asset/AssetRowStatus';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'asset-details-view',
	components: {
		AssetRowStatus,
		Loading,
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			selAsset: {},
			allUsersObj: {},
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		currentLocation() {
			if (this.selAsset && this.selAsset.currentLocation) {
				return this.selAsset.currentLocation;
			}
			return {};
		},
		details() {
			if (this.selAsset && this.selAsset.details) {
				return this.selAsset.details;
			}
			return {};
		},
		inventoriedBy() {
			let inventoriedBy = '';

			if (this.selAsset && this.selAsset.details) {
				let assetDetails = this.selAsset.details;

				if (!_.isEmpty(assetDetails.inventoriedBy)) {
					let user = this.allUsersObj[assetDetails.inventoriedBy];
					if (user && !_.isEmpty(user)) {
						inventoriedBy = user.firstName + ' ' + user.lastName;
						inventoriedBy =
							inventoriedBy + ' (' + assetDetails.inventoriedBy + ')';
					}
				}
			}
			return inventoriedBy;
		},
	},
	mounted() {
		this.allUsersObj = this.$store.getters.users;
		this.retrieveSelAsset(this.row.item.assetCode);
	},
	methods: {
		async retrieveSelAsset(assetCode) {
			this.selAsset = await this.$store.dispatch('getAssetById', assetCode);
		},
		showFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getNoOfDays(date) {
			return DateUtil.getNoOfDays(date, new Date());
		},
		updateSelAsset(item, _index, _button) {
			this.$store.commit('SET_CURR_ASSET', item);
			EventBus.$emit('onUpdateSelAsset', item);
		},
	},
};
</script>

<style scoped></style>
