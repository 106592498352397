import axios from 'axios';
import _ from 'lodash';
import config from '@/config/env-constants';


function getProjectId() {
    if (config.currEnv === 'pjl') {
        return `ayun-${config.currEnv}`;
    } else {
        return `ares-${config.currEnv}-app`;
    }
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    paginateAssets(page, limit, startAt, direction, filters, currUserId) {
        let url = `${this.baseUrl}/paginateAssets`;
        return axios.post(url, { ...filters, page, limit, startAt, direction, currUserId });
    },

    activateAssets(param) {
        let url = `${this.baseUrl}/activateAssets`;
        return axios.post(url, {
            currUserId: param.currUserId,
            dateActivated: param.dateActivated,
            operation: param.operation,
            currentLocation: JSON.stringify(param.currentLocation),
            assetCodes: _.join(param.assetCodes, ','),
            assetConditions: JSON.stringify(param.assetConditions)
        });
    },

    markAsLostAssets(param) {
        let url = `${this.baseUrl}/markAsLostAssets`;
        return axios.post(url, {
            currUserId: param.currUserId,
            dateDeactivated: param.dateDeactivated,
            currentLocation: JSON.stringify(param.currentLocation),
            assetCodes: _.join(param.assetCodes, ',')
        });
    },

    changeAssetsLocation(param) {
        let url = `${this.baseUrl}/changeAssetsLocation`;
        return axios.post(url, {
            currUserId: param.currUserId,
            dateUpdated: param.dateUpdated,
            operation: param.operation,
            currCompanyId: param.currCompanyId,
            newLocation: JSON.stringify(param.newLocation),
            assetCodes: _.join(param.assetCodes, ',')
        });
    },

    updateAssets(param) {
        let url = `${this.baseUrl}/updateAssets`;
        return axios.post(url, {
            currUserId: param.currUserId,
            currTimeStamp: param.currTimeStamp,
            assets: JSON.stringify(param.assets)
        });
    },

    saveAssetsDetails(param) {
        let url = `${this.baseUrl}/saveAssetsDetails`;
        return axios.post(url, {
            currUserId: param.currUserId,
            currTimeStamp: param.currTimeStamp,
            currStorageLocationId: param.currStorageLocationId,
            asset: JSON.stringify(param.asset),
            assetTypeCategory: JSON.stringify(param.assetTypeCategory),
            assetType: JSON.stringify(param.assetType),
            assetTypeCode: JSON.stringify(param.assetTypeCode)
        });
    }

}

