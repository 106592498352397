<template>
	<b-modal
		id="update-asset-details"
		:title="dialogTitle"
		ref="modal"
		ok-title="Update"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="12">
						<b-form-group
							label="Name"
							label-for="name"
							description="Allowed characters are numbers, letters, and some special characters: :, -, (, ), \\, /, and ,"
						>
							<b-form-input
								name="Name"
								type="text"
								v-model="selAsset.details.name"
								v-validate="{
									required: true,
									regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
								}"
								maxlength="50"
							/>
							<span v-show="errors.has('Name')" class="help-block">
								{{ errors.first('Name') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group
							label="Serial No"
							label-for="serialNo"
							description="Allowed characters are numbers, letters, and some special characters: :, -, (, ), \\, /, and ,"
						>
							<b-form-input
								name="Serial No"
								type="text"
								v-model="selAsset.details.serialNo"
								v-validate="{
									required: false,
									regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
								}"
								maxlength="50"
							/>
							<span v-show="errors.has('Serial No')" class="help-block">
								{{ errors.first('Serial No') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group
							label="Description"
							label-for="description"
							description
						>
							<b-form-textarea
								name="Description"
								type="text"
								v-model="selAsset.details.description"
								maxlength="200"
								:rows="3"
								placeholder="Description"
							/>
							<span v-show="errors.has('Description')" class="help-block">
								{{ errors.first('Description') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Condition">
							<b-form-select
								name="Condition"
								v-model="selAsset.details.condition"
								v-validate="'required'"
								:options="assetConditionOptions"
								class="mr-2"
							/>
							<span v-show="errors.has('Condition')" class="help-block">{{
								errors.first('Condition')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
	name: 'update-asset-details',
	components: {
		Loading,
		DateRangePicker,
	},
	props: {
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		allCompanyOptions: {
			type: Array,
			required: false,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selAsset: {
				details: {
					name: '',
					serialNo: '',
					description: '',
					condition: '',
				},
			},

			assetConditionOptions: config.assetConditionStatus,
			currUserId: this.$store.getters.loggedUser.id,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
			maxDate: new Date(
				DateUtil.endDateTimeStamp(DateUtil.getCurrentTimestamp())
			),
		};
	},
	watch: {},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		dialogTitle: function () {
			return 'Update Asset Details: ' + this.selAsset.assetCode;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelAsset', (selAsset) => {
			this.selAsset = selAsset;
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			this.handleSubmit();
		},
		getParam() {
			let assets = {};
			assets[this.selAsset.assetCode] = this.selAsset;

			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assets: assets,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetApi.updateAssets(this.getParam());

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseUpdateAssets', data.asset);
					this.$refs.modal.hide();
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.warning(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error('Error updating asset/s. Please try again.');
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAsset');
	},
};
</script>

<style scoped>
</style>


