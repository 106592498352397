var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"update-asset-details","title":_vm.dialogTitle,"ok-title":"Update","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true},on:{"ok":_vm.handleOk}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","description":"Allowed characters are numbers, letters, and some special characters: :, -, (, ), \\\\, /, and ,"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
								required: true,
								regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\tregex: /^([A-Za-z0-9\\s\\-:()\\\\/,]{1,50})$/,\n\t\t\t\t\t\t\t}"}],attrs:{"name":"Name","type":"text","maxlength":"50"},model:{value:(_vm.selAsset.details.name),callback:function ($$v) {_vm.$set(_vm.selAsset.details, "name", $$v)},expression:"selAsset.details.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Name')),expression:"errors.has('Name')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Name'))+" ")])],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Serial No","label-for":"serialNo","description":"Allowed characters are numbers, letters, and some special characters: :, -, (, ), \\\\, /, and ,"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
								required: false,
								regex: /^([A-Za-z0-9\s\-:()\\/,]{1,50})$/,
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: false,\n\t\t\t\t\t\t\t\tregex: /^([A-Za-z0-9\\s\\-:()\\\\/,]{1,50})$/,\n\t\t\t\t\t\t\t}"}],attrs:{"name":"Serial No","type":"text","maxlength":"50"},model:{value:(_vm.selAsset.details.serialNo),callback:function ($$v) {_vm.$set(_vm.selAsset.details, "serialNo", $$v)},expression:"selAsset.details.serialNo"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Serial No')),expression:"errors.has('Serial No')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Serial No'))+" ")])],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description","description":""}},[_c('b-form-textarea',{attrs:{"name":"Description","type":"text","maxlength":"200","rows":3,"placeholder":"Description"},model:{value:(_vm.selAsset.details.description),callback:function ($$v) {_vm.$set(_vm.selAsset.details, "description", $$v)},expression:"selAsset.details.description"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Description')),expression:"errors.has('Description')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Description'))+" ")])],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Condition"}},[_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mr-2",attrs:{"name":"Condition","options":_vm.assetConditionOptions},model:{value:(_vm.selAsset.details.condition),callback:function ($$v) {_vm.$set(_vm.selAsset.details, "condition", $$v)},expression:"selAsset.details.condition"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Condition')),expression:"errors.has('Condition')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Condition')))])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }