<template>
	<b-modal
		id="change-asset-location"
		size="lg"
		:title="dialogTitle"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		@show="onReset"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="6">
						<div class="mb-2">
							<b>
								<i class="icon-location-pin"></i> &nbsp;Last Known Location
							</b>
						</div>

						<b-form-group label="Company" label-for="" description="">
							<b>{{ company }}</b>
						</b-form-group>

						<b-form-group label="Storage Location" label-for="" description="">
							<b>{{ storageLocation }}</b>
						</b-form-group>
					</b-col>

					<b-col sm="6">
						<div class="mb-2">
							<b> <i class="icon-location-pin"></i> &nbsp;New Location </b>
						</div>

						<b-form-group label="Company:">
							<v-select
								name="Company"
								class="style-chooser"
								label="text"
								:options="companyOptions"
								:reduce="(company) => company.value"
								v-model="selCompany"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">{{
								errors.first('Company')
							}}</span>
						</b-form-group>

						<b-form-group label="Storage Location">
							<v-select
								name="Storage Location"
								class="style-chooser"
								label="text"
								:options="storageLocationOptions"
								:reduce="(loc) => loc.value"
								v-model="selStorageLocation"
								v-validate="'selectRequired'"
							>
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a storage location
									</em>
								</template>
							</v-select>
							<span
								v-show="errors.has('Storage Location')"
								class="help-block"
								>{{ errors.first('Storage Location') }}</span
							>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<b-col sm="12">
						<b>Other Details</b>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="6">
						<b-form-group label="Purpose">
							<b-form-select
								name="Purpose"
								v-model="selPurpose"
								v-validate="'required'"
								:options="purposeOptions"
								class="mr-2"
							/>
							<span v-show="errors.has('Purpose')" class="help-block">{{
								errors.first('Purpose')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group
							v-show="showOthersField"
							label="If Others, please specify"
							label-for="others"
							description
						>
							<b-form-input
								name="Others"
								v-model="others"
								type="text"
								placeholder="Please specify"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { LocationUtil } from '@/utils/locationUtil';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'change-asset-location',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allStorageLocationOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selOwnership: null,
			selPurpose: null,
			others: '',

			storageLocationOptions: [],
			currUserId: this.$store.getters.loggedUser.id,
			purposeOptions: config.changeLocationPurpose,
			showOthersField: false,

			selAsset: {},
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		companyId: function () {
			if (this.selAsset.currentLocation) {
				return this.selAsset.currentLocation.companyId;
			}
			return '';
		},
		company: function () {
			if (this.selAsset.currentLocation) {
				return this.selAsset.currentLocation.company;
			}
			return '';
		},
		storageLocation: function () {
			if (this.selAsset.currentLocation) {
				return this.selAsset.currentLocation.storageLocation;
			}
			return '';
		},
		dialogTitle: function () {
			return 'Change Asset Location: ' + this.selAsset.assetCode;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	watch: {
		selCompany: function () {
			if (!_.isEmpty(this.selCompany)) {
				this.retrieveStorageLocations();
			} else {
				this.resetStorageLocationEntries();
			}
		},
		selStorageLocation: function () {
			if (
				this.selStorageLocation.id ==
				this.selAsset.currentLocation.storageLocationId
			) {
				this.$toaster.warning('You should transfer to a different location.');
			}
		},
		selPurpose: function () {
			if (this.selPurpose === 'Others') {
				this.showOthersField = true;
			} else {
				this.showOthersField = false;
			}
		},
	},
	mounted() {
		this.resetStorageLocationEntries();

		EventBus.$on('onUpdateSelAsset', (selAsset) => {
			this.selAsset = selAsset;
		});
	},
	methods: {
		resetStorageLocationEntries() {
			// reset entries
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		retrieveStorageLocations() {
			let vm = this;

			this.resetStorageLocationEntries();

			this.allStorageLocationOptions.forEach((locOption) => {
				let loc = locOption.value;
				if (loc.companyId === vm.selCompany.id) {
					vm.storageLocationOptions.push({
						value: { id: loc.id, name: loc.name, geoaddress: loc.geoaddress },
						text: loc.name,
					});
				}
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return;
			}

			// if purpose is Others, Others field is required
			if (this.selPurpose === 'Others' && this.others.length === 0) {
				this.$toaster.warning('Please specify the purpose of location change.');
				return;
			}

			// the new location must not be the same with the previous location
			let currentLocation = this.selAsset.currentLocation;
			if (
				this.selCompany.id === currentLocation.companyId &&
				this.selStorageLocation.id === currentLocation.storageLocationId
			) {
				this.$toaster.warning('You should transfer to a different location.');
				return;
			}

			this.handleSubmit();
		},
		prepareCurrentLocation() {
			let notes = this.selPurpose;
			if (this.selPurpose === 'Others') {
				// Removes excess whitespace
				notes = ValidationUtil.removeExcessWhiteSpace(this.others);
			}

			return {
				company: this.selCompany.name,
				companyId: this.selCompany.id,
				storageLocation: this.selStorageLocation.name,
				storageLocationId: this.selStorageLocation.id,
				geoaddress: LocationUtil.getGeoaddress(
					this.selStorageLocation.geoaddress
				),
				dateStored: DateUtil.getCurrentTimestamp(),
				storedBy: this.currUserId,
				notes: notes,
			};
		},
		getParam() {
			let param = {
				currUserId: this.currUserId,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				newLocation: this.prepareCurrentLocation(),
				assetCodes: [],
				operation: config.assetHistoryOperation.CHANGE_ASSET_LOCATION,
			};
			param.assetCodes.push(this.selAsset.assetCode);

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetApi.changeAssetsLocation(this.getParam());

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					this.$refs.modal.hide();
					EventBus.$emit('onCloseChangeAssetLocation', data.assets);
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.warning(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					'Error changing location of asset/s. Please try again.'
				);
			}
		},
		onReset() {
			this.selCompany = config.companyDefaultValue;
			this.selStorageLocation = config.storageLocationDefaultValue;
			this.selOwnership = null;
			this.selPurpose = null;
			this.others = '';
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAsset');
	},
};
</script>

<style scoped>
</style>


