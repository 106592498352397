<template>
	<b-modal
		id="mark-as-lost-assets"
		title="Mark as Lost Assets"
		size="lg"
		ref="modal"
		ok-title="Save"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="mb-2">
					<b-col sm="12" class="mb-2">
						<b> <i class="icon-eyeglass"></i> &nbsp;Last Known Locations </b>
					</b-col>
					<b-col sm="12" class="mb-4">
						NOTE: You can only mark as lost those assets with active status. The
						following are the list of all selected assets with
						<b-badge variant="primary">Stored</b-badge>status.
					</b-col>
					<b-col>
						<b-table
							show-empty
							striped
							hover
							:items="activeAssets"
							:fields="fields"
							:current-page="currentPage"
							:per-page="perPage"
							:filter="filter"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:sort-direction="sortDirection"
							responsive
						/>
					</b-col>
				</b-row>
				<b-row class="mb-4">
					<b-col md="4" class="my-1">
						<b-pagination
							:total-rows="totalRows"
							:per-page="perPage"
							v-model="currentPage"
							class="my-0"
						/>
					</b-col>
					<b-col md="2" sm="12" offset-md="2" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="6" class="my-1">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col sm="12">Are you sure to mark these assets as lost?</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'mark-as-lost-assets',
	components: {
		Loading,
	},
	data() {
		return {
			activeAssets: [],
			fields: [
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'status',
					sortable: true,
				},
				{
					key: 'currentLocation.company',
					label: 'Current Company',
					sortable: false,
				},
				{
					key: 'currentLocation.storageLocation',
					label: 'Current Storage Location',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			currUserId: this.$store.getters.loggedUser.id,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelAssetsToMarkAsLost', (selAssets) => {
			this.processSelAssets(selAssets);
		});
	},
	methods: {
		async processSelAssets(selAssets) {
			let vm = this;

			// Show Loader
			this.Loading = true;

			let assetCodes = _.map(selAssets, 'assetCode');
			let assetsObjResult = await this.$store.dispatch('getAssetsByAssetCode', {
				assetCodes: assetCodes,
			});
			let assetsObj = assetsObjResult[0];

			// reset stored and inactive assets
			this.activeAssets = [];

			let assetsArr = Object.values(assetsObj);
			assetsArr.forEach((asset) => {
				if (asset.status === 'Stored' || asset.status === 'In-Transit') {
					vm.activeAssets.push(asset);
				}
			});
			this.totalRows = this.activeAssets.length;

			// Hide Loader
			this.Loading = false;
		},
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let inTransitAssets = _.filter(this.activeAssets, (o) => {
				return o.status === 'In-Transit';
			});
			let inTransitAssetCodes = _.map(inTransitAssets, 'assetCode');

			if (inTransitAssetCodes.length > 0) {
				this.$toaster.error(
					'Unable to mark as lost In-Transit asset(s) ' +
						JSON.stringify(inTransitAssets)
				);
				return;
			}

			this.handleSubmit();
		},
		getParam() {
			let param = {
				currUserId: this.currUserId,
				dateDeactivated: DateUtil.getCurrentTimestamp(),
				assetCodes: [],
			};

			this.activeAssets.forEach((asset) => {
				if (asset.status !== 'In-Transit') {
					param.assetCodes.push(asset.assetCode);
				}
			});

			return param;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetApi.markAsLostAssets(this.getParam());

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseMarkAsLostAssets', data.assets);
					this.$refs.modal.hide();
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.warning(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error('Error marking asset/s as lost. Please try again.');
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAssetsToMarkAsLost');
	},
};
</script>

<style scoped>
</style>


