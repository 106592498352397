<template>
	<div>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<b-row class="mt-2">
			<b-col sm="12" md="3" lg="3">
				<b-button
					v-b-popover.hover.right="'Toogle to show/hide filter options'"
					v-b-toggle.collapse-1
					class="filter"
				>
					FILTER OPTIONS
				</b-button>
			</b-col>
			<b-col sm="12">
				<!-- Collapsible Filter Options -->
				<b-collapse id="collapse-1" class="mt-2">
					<b-card>
						<b-row no-gutters>
							<b-col lg="4" md="6" sm="12" class="mr-2">
								<b-form-group label="Company">
									<v-select
										class="style-chooser"
										label="text"
										:options="filterByOptions.companyItems"
										:reduce="(company) => company.value"
										v-model="filterBy.company"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												No results found for
												<em>
													<strong>{{ search }}</strong>
												</em>
											</template>
											<em :style="{ opacity: 0.5 }" v-else>
												Start typing to search for a company
											</em>
										</template>
									</v-select>
								</b-form-group>
							</b-col>
							<b-col lg="4" md="6" sm="12" class="mr-2">
								<b-form-group label="Status">
									<b-form-select
										v-model="filterBy.status"
										:options="statusItems"
										class="mr-2"
									/>
								</b-form-group>
							</b-col>
							<b-col lg="4" md="6" sm="12" class="mr-2">
								<b-form-group label="Asset Type">
									<v-select
										class="style-chooser"
										label="text"
										:options="filterByOptions.assetTypeItems"
										:reduce="(assetType) => assetType.value"
										v-model="filterBy.assetType"
									>
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
												No results found for
												<em>
													<strong>{{ search }}</strong>
												</em>
											</template>
											<em :style="{ opacity: 0.5 }" v-else>
												Start typing to search for a asset type
											</em>
										</template>
									</v-select>
								</b-form-group>
							</b-col>
							<b-col lg="4" md="6" sm="12" class="mr-2">
								<b-form-group label="Asset Code">
									<b-form-input
										name="assetCode"
										type="search"
										v-model="filterBy.assetCode"
										v-validate="'required'"
										placeholder
									/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row no-gutters>
							<b-col sm="12">
								<span class="text-nowrap">
									<b-button variant="success" @click="onFilterRequest"
										>Search</b-button
									>&nbsp;&nbsp;
									<b-button variant="primary" @click="resetFilters"
										>Reset</b-button
									>
								</span>
							</b-col>
						</b-row>
					</b-card>
				</b-collapse>
			</b-col>
		</b-row>

		<div>
			<b-row>
				<b-col sm="6" md="3" class="mt-4 mb-2">
					<b-dropdown
						text=" Select Actions "
						variant="dark"
						slot="append"
						v-show="!isViewer"
					>
						<b-dropdown-item @click="updateSelAssetsToActivate">
							Activate Asset(s)
						</b-dropdown-item>
						<b-dropdown-item @click="updateSelAssetsToMarkAsLost">
							Mark as Lost Asset(s)
						</b-dropdown-item>
						<b-dropdown-item @click="updateSelAssetsToChangeLocation">
							Change Asset(s) Location
						</b-dropdown-item>
						<b-dropdown-item @click="updateSelAssetsToAssignCondition">
							Assign Asset(s) Condition
						</b-dropdown-item>
					</b-dropdown>
				</b-col>
				<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
					<b-input-group prepend="Show" append="/ Page">
						<b-form-select
							:options="pageOptions"
							v-model="pagination.perPage"
						/>
					</b-input-group>
				</b-col>
			</b-row>
		</div>

		<b-table
			ref="accountableAssetsTable"
			show-empty
			striped
			hover
			:items="items"
			:fields="fields"
			responsive
			selectable
			select-mode="multi"
			@row-clicked="rowClicked"
			selected-variant="primary"
		>
			<template v-slot:cell(selected)="{ rowSelected }">
				<template v-if="rowSelected && !isViewer">
					<span aria-hidden="true">&check;</span>
					<span class="sr-only">Selected</span>
				</template>
				<template v-else>
					<span aria-hidden="true">&nbsp;</span>
					<span class="sr-only">Not selected</span>
				</template>
			</template>

			<template v-slot:cell(actions)="row">
				<AssetRowActions :row="row" />
			</template>

			<template slot="row-details" slot-scope="row">
				<AssetDetailsView :row="row" :isViewer="isViewer" />
			</template>

			<template v-slot:cell(status)="row">
				<AssetRowStatus :selAsset="row.item" />
			</template>
		</b-table>

		<b-row>
			<b-col md="4" sm="12" class="my-1">
				<b-button size="sm" @click="selectAllRows" v-show="!isViewer"
					>Select all</b-button
				>&nbsp;&nbsp;
				<b-button size="sm" @click="clearSelectedRows" v-show="!isViewer"
					>Clear selected</b-button
				>&nbsp;&nbsp;
			</b-col>
			<b-col md="4" sm="12" class="my-1">
				<span class="totalDisplay" v-show="!isViewer">
					Selected: {{ Object.keys(selected).length }}
				</span>
			</b-col>
			<b-col md="4" sm="12">
				<b-pagination
					align="right"
					@change="onPageChanged"
					:total-rows="pagination.total"
					:per-page="pagination.perPage"
					v-model="pagination.currentPage"
					class="my-0"
					limit="1"
					prev-text="Prev"
					next-text="Next"
					hide-goto-end-buttons
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Components
import AssetRowStatus from '@/views/asset/asset/AssetRowStatus';
import AssetRowActions from '@/views/asset/asset/AssetRowActions';
import AssetDetailsView from '@/views/asset/asset/AssetDetailsView';

// Utils
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import assetApi from '@/api/assetApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'accountable-assets',
	components: {
		AssetRowStatus,
		AssetRowActions,
		AssetDetailsView,
		Loading,
	},
	props: {
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
		filterByOptions: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'assetType',
				},
				{
					key: 'company',
					label: 'Company User',
				},
				{
					key: 'status',
				},
				'actions',
			],
			pageOptions: [5, 10, 15, 25, 50, 100],
			pagination: {
				perPage: 10,
				currentPage: 1,
				total: 0,
				startAt: '',
				endAt: '',
			},

			statusItems: [
				{
					value: 'Inactive',
					text: 'Inactive',
				},
				{
					value: 'Stored',
					text: 'Stored',
				},
				{
					value: 'In-Transit',
					text: 'In-Transit',
				},
				{
					value: 'Lost',
					text: 'Lost',
				},
			],

			defaultFilterBy: {
				company: config.companyDefaultValue,
				status: 'Stored',
				assetType: config.assetTypeDefaultValue,
				assetCode: '',
				direction: 'asc',
			},
			filterBy: {
				company: config.companyDefaultValue,
				status: 'Stored',
				assetType: config.assetTypeDefaultValue,
				assetCode: '',
				direction: 'asc',
			},
			prevFilterBy: {},

			allAssetsObj: {},

			// array of selecteds from table
			selected: [],

			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		'pagination.perPage'(value) {
			this.resetPagination();
			this.retrieveData(1, value, this.pagination.startAt, {
				...this.filterBy,
			});
		},
		'pagination.currentPage'(newVal, oldVal) {
			if (newVal === 1) {
				this.resetPagination();
				this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
					...this.filterBy,
				});
			} else {
				if (newVal > oldVal) {
					this.filterBy.direction = 'asc';
					this.retrieveData(
						newVal,
						this.pagination.perPage,
						this.pagination.endAt,
						{
							...this.filterBy,
						}
					);
				} else {
					this.filterBy.direction = 'desc';
					this.retrieveData(
						newVal,
						this.pagination.perPage,
						this.pagination.startAt,
						{
							...this.filterBy,
						}
					);
				}
			}
		},
		items(value) {
			this.$nextTick(() => {
				// Apply existing highlighting for selected rows
				let selectedItems = _.intersectionBy(value, this.selected, 'id');

				selectedItems.forEach((item) => {
					// retrieve index with highlights
					let index = value.indexOf(item);
					// apply hightlights programmatically
					this.$refs.accountableAssetsTable.selectRow(index);
				});
			});
		},
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.filterBy.company = DropDownItemsUtil.getCompanyItem(
				this.loggedUserCompany
			);
			this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
				this.loggedUserCompany
			);
		}

		this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
			...this.filterBy,
		});

		// Events Receiver
		EventBus.$on('onCloseActivateAsset', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});

		EventBus.$on('onCloseActivateAssets', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});

		EventBus.$on('onCloseChangeAssetLocation', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});

		EventBus.$on('onCloseChangeAssetsLocation', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});

		EventBus.$on('onCloseMarkAsLostAsset', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});

		EventBus.$on('onCloseMarkAsLostAssets', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});

		EventBus.$on('onCloseAssignConditionOnAssets', (assetsObj) => {
			if (!_.isEmpty(assetsObj)) {
				this.updateTable(assetsObj);
			}
			this.clearSelectedRows();
		});
	},
	methods: {
		updateTable(assetsObj) {
			_.forEach(assetsObj, (value, key) => {
				this.allAssetsObj[key] = value;

				// update items from asset table
				let index = _.findIndex(this.items, (o) => {
					return o.assetCode === key;
				});
				if (index >= 0) {
					this.items[index] = value;
				}
			});
			this.$store.dispatch('updateAllAssets', this.allAssetsObj);

			let filteredObj = _.groupBy(this.items, 'id');
			_.forEach(filteredObj, (value, key) => {
				// filter by company
				if (
					!_.isEmpty(this.filterBy.company.id) &&
					value.companyId !== this.filterBy.company.id
				) {
					delete filteredObj[key];
				}
				// filter by status
				if (
					!_.isEmpty(this.filterBy.status) &&
					value.status !== this.filterBy.status
				) {
					delete filteredObj[key];
				}
				// filter by assetType
				if (
					!_.isEmpty(this.filterBy.assetType.id) &&
					value.assetTypeId !== this.filterBy.assetType.id
				) {
					delete filteredObj[key];
				}
				// filter by assetCode
				if (
					!_.isEmpty(this.filterBy.assetCode) &&
					value.assetCode !== this.filterBy.assetCode
				) {
					delete filteredObj[key];
				}
			});

			if (_.size(filteredObj) <= this.items.length) {
				this.pagination.startAt = '';
				if (_.size(filteredObj) > 0) {
					this.pagination.startAt = Object.values(filteredObj)[0];
				}
				this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
					...this.filterBy,
				});
			}
		},

		resetPagination() {
			this.filterBy.direction = 'asc';
			this.pagination.startAt = '';
			this.pagination.endAt = '';
		},
		onPageChanged(currentPage) {
			this.pagination.currentPage = currentPage;
		},

		isValidAssetCode(assetCode) {
			let assetTypesObj = this.$store.getters.assetTypes;
			return (
				_.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(assetTypesObj, assetCode)
			);
		},
		validateFilter() {
			let isValid = true;

			if (this.filterBy.company.id === null) {
				this.$toaster.warning('Company is required.');
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			}

			return isValid;
		},
		onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			// reset startAt during fresh query
			this.pagination.startAt = '';

			this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
				...this.filterBy,
			});
			this.prevFilter = Object.assign({}, this.filterBy);

			// clear selected rows
			this.clearSelectedRows();
		},
		resetFilters() {
			// reset to default
			this.filterBy = Object.assign({}, this.defaultFilterBy);
			this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
				...this.filterBy,
			});
			this.prevFilter = Object.assign({}, this.filterBy);

			// clear selected rows
			this.clearSelectedRows();
		},

		async retrieveData(page, perPage, startAt, filterBy) {
			// Show Loader
			this.isLoading = true;

			try {
				let filters = {
					companyId: !filterBy.company.id ? '' : filterBy.company.id,
					status: !filterBy.status ? '' : filterBy.status,
					assetTypeId: !filterBy.assetType.id ? '' : filterBy.assetType.id,
					assetCode: !filterBy.assetCode ? '' : filterBy.assetCode,
				};

				const { data } = await assetApi.paginateAssets(
					page,
					perPage,
					startAt,
					filterBy.direction,
					filters,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.items = data.items;

					let newStartAt = '';
					let newEndAt = '';
					if (!_.isEmpty(this.items)) {
						newStartAt = this.items[0].assetCode;
						newEndAt = this.items[this.items.length - 1].assetCode;
					}

					let total = 0;
					if (this.items.length < data.perPage) {
						total = data.perPage * (data.currentPage - 1) + this.items.length;
					} else {
						total = data.perPage * data.currentPage + 1;
					}

					this.pagination = {
						perPage: data.perPage,
						currentPage: data.currentPage,
						total: total,
						startAt: newStartAt,
						endAt: newEndAt,
					};
				} else {
					this.$toaster.error(
						'Error loading data. Please reload the page again.'
					);
				}
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// Hide Loader
			this.isLoading = false;
		},

		rowClicked(item) {
			if (_.findIndex(this.selected, { id: item.id }) < 0) {
				this.selected.push(item);
			} else {
				for (let i = 0; i < this.selected.length; i++) {
					if (this.selected[i].id === item.id) {
						this.selected.splice(i, 1);
						break;
					}
				}
			}
		},
		selectAllRows() {
			this.$refs.accountableAssetsTable.selectAllRows();
			this.items.forEach((item) => {
				if (_.findIndex(this.selected, { id: item.id }) < 0) {
					this.selected.push(item);
				}
			});
		},
		clearSelectedRows() {
			this.$refs.accountableAssetsTable.clearSelected();

			// clear selected rows
			this.selected = [];
		},

		// Asset Operations
		updateSelAssetsToActivate() {
			if (this.selected.length === 0) {
				this.$toaster.warning('Please select atleast 1 asset.');
				return;
			} else if (
				_.findIndex(this.selected, { status: 'Lost' }) < 0 &&
				_.findIndex(this.selected, { status: 'Inactive' }) < 0
			) {
				this.$toaster.warning(
					'Please select at least one "Inactive" or "Lost" asset.'
				);
				return;
			}

			this.$bvModal.show('activate-assets');
			EventBus.$emit('onUpdateSelAssetsToActivate', this.selected);
		},
		updateSelAssetsToMarkAsLost() {
			if (this.selected.length === 0) {
				this.$toaster.warning('Please select at least 1 asset.');
				return;
			} else if (_.findIndex(this.selected, { status: 'Stored' }) < 0) {
				this.$toaster.warning('Please select at least one "Stored" asset.');
				return;
			}

			this.$bvModal.show('mark-as-lost-assets');
			EventBus.$emit('onUpdateSelAssetsToMarkAsLost', this.selected);
		},
		updateSelAssetsToChangeLocation() {
			if (this.selected.length === 0) {
				this.$toaster.warning('Please select at least 1 asset.');
				return;
			} else if (_.findIndex(this.selected, { status: 'Stored' }) < 0) {
				this.$toaster.warning('Please select at least one "Stored" asset.');
				return;
			}

			this.$bvModal.show('change-assets-location');
			EventBus.$emit('onUpdateSelAssetsToChangeLocation', this.selected);
		},
		updateSelAssetsToAssignCondition() {
			if (this.selected.length === 0) {
				this.$toaster.warning('Please select at least 1 asset.');
				return;
			}

			this.$bvModal.show('assign-condition-on-assets');
			EventBus.$emit('onUpdateSelAssetsToAssignCondition', this.selected);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseActivateAsset');
		EventBus.$off('onCloseActivateAssets');
		EventBus.$off('onCloseChangeAssetLocation');
		EventBus.$off('onCloseChangeAssetsLocation');
		EventBus.$off('onCloseMarkAsLostAsset');
		EventBus.$off('onCloseMarkAsLostAssets');
		EventBus.$off('onCloseAssignConditionOnAssets');
	},
};
</script>

<style scoped>
</style>